import axios from 'axios'
import * as Lockr from "lockr"; 
const host = '/prod-api/'

// const apihost = '/api/'
const apihost = host+'api/'
axios.defaults.baseURL = apihost
axios.defaults.imgURL = host
axios.defaults.timeout = 1000 * 150
axios.defaults.headers['Content-Type'] = 'application/json'

//注入access_token
axios.interceptors.request.use(function(config) { 
	return config;
}, function(error) { 
	return Promise.reject(error);
});
//401跳登陆
axios.interceptors.response.use(response => {  
	if(response){
		let res = response.data
		res.state = res.code == 200 
		let strJson = JSON.stringify(res.data)
		if(strJson){
			let reg = new RegExp('www.lyguohongtouzi.com','g')
			let newStr = strJson.replace(reg,'lygkjt.com')
			res.oldData = res.data
			res.data = JSON.parse(newStr)
		}
		return res;
	}
	return {}
}, error => {  
	return Promise.reject(error);
});

export function get(url, para, cb) {
	axios.get(url, {
		params: para
	}).then(res => { 
		cb(res);
	}).catch(e=>{ 
		cb(e)
	})
}


export default axios;